import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Book Reading App Template | Uizard"
    canonical="/templates/mobile-app-templates/book-reading-mobile-app/"
    category="Tablet Templates"
    url="/templates/tablet-templates/book-reading-tablet-app/"
    metaDescription="Design an online banking app for tablets in minutes with Plutus, Uizard's banking app design template. Explore now."
    description="
    h2:Readium: Our book reading tablet app design template
    <br/>
    Is there anything better than a good book? Discover Readium, Uizard's book reading tablet app design template. Packed with all the must-have features you would find across any of the most popular reading apps out there, Readium is ready to go straight out of the box for your latest design project.
    <br/>
    h3:Tell your own story with limitless customization
    <br/>
    Readium is a real page-turner, but don't just take our word for it… To get started, simply open the <a:https://uizard.io/templates/>UI template</a> and start customizing it to fit your vision. Sure, Readium is good to go right out of the box, but with Uizard's multitude of customization options, why not personalize your app design a little?
    <br/>
    h3:Become the author of your own success with rapid iteration
    <br/>
    You've heard of speed reading, Uizard now brings you speed designing… Uizard is made with ease of use and speed in mind. You can go from idea to workable prototype in a matter of minutes. Simply open the template, tweak it to suit your needs, and advance on to <a:https://uizard.io/prototyping/>UX/UI prototype</a> format in no time at all.
    "
    pages={[
      "Minimalist homepage and signup screens",
      "A gorgeous book reading directory with the status of current reads and upcoming books",
      "An intuitive book marketplace screen, with expanded book descriptions and relevant CTAs",
      "A reading interface mockup screen",
      "Book suggestions directory for new discoveries",
    ]}
    projectCode="3oz60VMMKPIrngd1V6nz"
    img1={data.image1.childImageSharp}
    img1alt="book reading tablet app design template cover"
    img2={data.image2.childImageSharp}
    img2alt="book reading tablet app design template favorite books screen"
    img3={data.image3.childImageSharp}
    img3alt="book reading tablet app design template discover screen"
    img4={data.image4.childImageSharp}
    img4alt="book reading tablet app design template book detail screen"
    img5={data.image5.childImageSharp}
    img5alt="book reading tablet app design template summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/book-reading-tablet-app/book-tab-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/book-reading-tablet-app/book-tab-app-favs.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/book-reading-tablet-app/book-tab-app-discover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/book-reading-tablet-app/book-tab-app-detail.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/book-reading-tablet-app/book-tab-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
